import {combineReducers, AnyAction} from 'redux';
import types from './types';
import {assign} from 'lodash';
// Helpers
import {Session} from '@brightlive/shared/helpers/interfaces';

interface TicketType {
  announcements: Array<Session>;
  ticketed: Array<Session>;
}

interface SessionTime {
  upcoming: TicketType;
  past: TicketType;
}

interface AllSessionInitState {
  data: SessionTime;
  success?: boolean;
  error?: null | {message: string};
  loading: boolean;
}

const allSessionInitState: AllSessionInitState = {
  data: {
    upcoming: {
      announcements: [],
      ticketed: [],
    },
    past: {
      announcements: [],
      ticketed: [],
    },
  },
  success: false,
  error: null,
  loading: false,
};

const all = (state = allSessionInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_ALL_SESSIONS}_REQUEST`:
      return assign({}, state, {
        success: false,
        error: null,
        loading: true,
      });
    case `${types.GET_ALL_SESSIONS}_SUCCESS`:
      return assign(
        {},
        {
          data: action?.data,
          error: null,
          success: true,
          loading: false,
        }
      );
    case `${types.GET_ALL_SESSIONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export type InitialSessionFormState = Session & {
  notes: string;
  created?: boolean;
  success?: boolean;
  updated?: boolean;
  error?: null | {message: string};
};

const initialSessionFormState: InitialSessionFormState = {
  availability: [
    {
      startTime: {label: '', value: ''},
      endTime: {label: '', value: ''},
      date: '',
      priority: 1,
    },
  ],
  createdAt: new Date(),
  category: null,
  duration: '',
  id: '',
  isVisible: false,
  isAnnouncement: true,
  mainImage: '',
  notes: '',
  shortDescription: '',
  longDescription: '',
  slug: '',
  creatorSlug: '',
  startDate: null,
  status: '',
  subcategory: null,
  tags: [],
  ticketPrice: null,
  ticketsForSale: false,
  timezone: '',
  title: '',
  totalTickets: null,
  totalTicketsSold: 0,
  verticalImage: '',
  video: '',
  publishedAt: null,
  revisionStatus: '',
  created: false,
  success: false,
  updated: false,
  error: null,
};

const sessionForm = (state = initialSessionFormState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_SESSION}_REQUEST`:
    case `${types.CREATE_SESSION}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: null,
      });
    case `${types.RESET_SESSION_FORM}`:
      return assign({}, initialSessionFormState, {
        success: false,
        created: false,
        updated: false,
        error: null,
      });
    case `${types.GET_SESSION}_SUCCESS`:
    case `${types.UPDATE_SESSION_FORM}`:
      return assign({}, state, {
        success: true,
        created: false,
        updated: false,
        error: null,
        ...action.data,
      });
    case `${types.CREATE_SESSION}_SUCCESS`:
      return assign({}, state, {
        success: false,
        created: true,
        updated: false,
        error: null,
        ...action.data,
      });
    case `${types.GET_SESSION}_FAILURE`:
    case `${types.CREATE_SESSION}_FAILURE`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

type InitialRevisionState = {
  session?: Session;
  changes: Record<string, unknown>;
  status: string;
  notes: string;
  reviewerID: string;
  sessionID: string;
  success?: boolean;
  updated?: boolean;
  error?: null | {message: string};
};

const initialRevisionFormState: InitialRevisionState = {
  session: initialSessionFormState,
  changes: {
    availability: [
      {
        startTime: {label: '', value: ''},
        endTime: {label: '', value: ''},
        date: '',
        priority: 1,
      },
    ],
  },
  status: 'pending-review',
  notes: '',
  reviewerID: '',
  sessionID: '',
};

const revisionForm = (state = initialRevisionFormState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_SESSION}_REQUEST`:
    case `${types.UPDATE_SESSION}_REQUEST`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: null,
      });
    case `${types.RESET_REVISION_FORM}`:
      return assign({}, initialRevisionFormState, {
        success: false,
        updated: false,
        error: null,
      });
    case `${types.GET_SESSION}_SUCCESS`:
    case `${types.UPDATE_REVISION_FORM}`:
      return assign({}, state, {
        success: true,
        updated: false,
        error: null,
        ...action.data,
      });
    case `${types.UPDATE_SESSION}_SUCCESS`:
      return assign({}, state, {
        success: false,
        updated: true,
        error: null,
        ...action.data,
      });
    case `${types.GET_SESSION}_FAILURE`:
    case `${types.UPDATE_SESSION}_FAILURE`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

const isAnnouncementContinuation = (state = false, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_ANNOUNCEMENT_CONTINUATION}`:
      return action.isContinuation;
    default:
      return state;
  }
};

interface InitialSubmittedState {
  isSubmitted: boolean;
}

const initialSessionSubmittedState: InitialSubmittedState = {
  isSubmitted: false,
};

const sessionSubmitted = (
  state = initialSessionSubmittedState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.SET_SESSION_SUBMITTED}`:
      return assign({}, state, {
        isSubmitted: action.isSubmitted,
      });
    default:
      return state;
  }
};

interface SessionState {
  all: AllSessionInitState;
  sessionForm: InitialSessionFormState;
  revisionForm: InitialRevisionState;
  isAnnouncementContinuation: boolean;
  sessionSubmitted: InitialSubmittedState;
}
export interface SessionReducer {
  session: SessionState;
}

export default combineReducers({
  all,
  sessionForm,
  revisionForm,
  isAnnouncementContinuation,
  sessionSubmitted,
});
