/* eslint-disable node/no-extraneous-import */
import App from 'next/app';
import {connect} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import {reduxWrapper} from '../redux/store';
import {initFirebase} from '../library/firebase';
import {AuthProvider} from '../components/shared/AuthProvider';
import {updateWindowWidth, updateWindowHeight} from '../redux/ui/actions';
import {Router} from 'next/router';
import NProgress from 'nprogress';
// Styles
import {theme} from '@brightlive/shared/styles/theme';
import 'video.js/dist/video-js.css';
import GlobalStyles from '../styles/globalStyles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'video.js/dist/video-js.css';

initFirebase(); // init Firebase
// Init progress bar
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({showSpinner: false});

interface AppInterface {
  updateWindowWidth: Function;
  updateWindowHeight: Function;
}
class CustomApp extends App<AppInterface> {
  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  updateWindowSize = () => {
    this.props.updateWindowWidth(window.innerWidth);
    this.props.updateWindowHeight(window.innerHeight);
  };

  render() {
    const {Component, pageProps} = this.props;

    return (
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Component {...pageProps} />
        </ThemeProvider>
      </AuthProvider>
    );
  }
}

export default reduxWrapper.withRedux(
  connect(null, {
    updateWindowWidth: updateWindowWidth,
    updateWindowHeight: updateWindowHeight,
  })(CustomApp)
);
