import {AnyAction, combineReducers} from 'redux';
import {HYDRATE} from 'next-redux-wrapper';
// Reducers
import auth from './auth/reducer';
import ui from './ui/reducer';
import session from './session/reducer';
import user from './user/reducer';
import payout from './payout/reducer';

const appReducer = combineReducers({
  auth,
  ui,
  session,
  user,
  payout,
});

let hydrated = false;
/**
 * Create redux Root Reducer
 *
 * @param   {RootReducer}  state current reducer state
 * @param   {AnyAction}  action current action triggered
 *
 * @return  {RootReducer} app reducer
 */
const rootReducer = (state, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      if (!hydrated) {
        hydrated = typeof window !== 'undefined';
        return {
          ...state,
          ...action.payload,
        };
      }
      return state;
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
