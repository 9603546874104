import 'styled-components';
import {assign} from 'lodash';

interface Color {
  transparent: string;
  white: string;
  grey50: string;
  grey100: string;
  grey200: string;
  grey300: string;
  grey400: string;
  grey500: string;
  grey600: string;
  grey700: string;
  grey800: string;
  black: string;
  trueBlack: string;
  neon500: string;
  neon600: string;
  neon700: string;
  pink100: string;
  pink500: string;
  pink800: string;
  red100: string;
  red400: string;
  red500: string;
  red600: string;
  red700: string;
  red800: string;
  scarlet100: string;
  scarlet500: string;
  scarlet800: string;
  orange100: string;
  orange500: string;
  orange700: string;
  orange800: string;
  cactus100: string;
  cactus500: string;
  cactus800: string;
  green100: string;
  green400: string;
  green500: string;
  green600: string;
  green700: string;
  green800: string;
  indigo100: string;
  indigo300: string;
  indigo500: string;
  indigo700: string;
  indigo800: string;
  // legacy colors
  yellow: string;
  blue: string;
  lavenderGrey: string;
  blueGrey: string;
  pewter: string;
  stone: string;
  ash: string;
  grey: string;
  lilac: string;
  silver: string;
  mint: string;
  indigo: string;
}

interface BackgroundColor {
  backgroundBody: string;
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundTertiary: string;
  backgroundInversePrimary: string;
  backgroundInverseSecondary: string;
  backgroundScrim: string;
  backgroundBlackTransparent: string;
  actionBrand: string;
  actionBrandTransparent: string;
  backgroundSubduedNegative: string;
  backgroundNegative: string;
  backgroundWarning: string;
  backgroundSubduedPositive: string;
  backgroundPositive: string;
  backgroundSubduedInformational: string;
  backgroundInformational: string;
  backgroundDisabled: string;
  backgroundBlack: string;
  backgroundWhite: string;
  backgroundInverseDisabled: string;
  actionHover: string;
  actionInverseHover: string;
  actionPressed: string;
  actionInversePressed: string;
  backgroundTransparent: string;
}

interface TextColor {
  textDefault: string;
  textSubdued: string;
  textDisabled: string;
  textInverse: string;
  textInverseSubdued: string;
  textInverseDisabled: string;
  textNegative: string;
  textPositive: string;
  textWarning: string;
  textBrand: string;
  textWhite: string;
  textBlack: string;
}

interface IconColor {
  iconDefault: string;
  iconDisabled: string;
  iconInverse: string;
  iconNegative: string;
  iconPositive: string;
}

interface BorderColor {
  borderDefault: string;
  borderSubdued: string;
  borderPrimary: string;
  borderInverse: string;
  borderInverseSubdued: string;
  borderInversePrimary: string;
  borderWhite: string;
  borderNegative: string;
  actionBrand: string;
}

interface FocusColor {
  focus: string;
  focusInverse: string;
}
interface Elevation {
  level1: string;
  level2: string;
  level3: string;
  level4: string;
  level5: string;
}
interface Spacing {
  '2XS': string;
  XS: string;
  SM: string;
  MD: string;
  LG: string;
  XL: string;
  '2XL': string;
  '3XL': string;
  '4XL': string;
  '5XL': string;
}
interface Margin {
  mobile: {
    page: string;
  };
  desktop: {
    page: string;
    panel: string;
  };
}
interface BorderWidth {
  default: string;
  focus: string;
}
interface BorderRadius {
  XS: string;
  SM: string;
  MD: string;
  LG: string;
  XL: string;
  '2XL': string;
}

interface FontSize {
  ['25']: string;
  ['50']: string;
  ['100']: string;
  ['200']: string;
  ['300']: string;
  ['400']: string;
  ['500']: string;
  ['600']: string;
  ['700']: string;
  ['800']: string;
  ['900']: string;
  ['1000']: string;
  ['1100']: string;
}

interface LineHeight {
  ['25']: string;
  ['50']: string;
  ['100']: string;
  ['200']: string;
  ['300']: string;
  ['400']: string;
  ['500']: string;
  ['600']: string;
  ['700']: string;
  ['800']: string;
  ['900']: string;
  ['1000']: string;
  ['1100']: string;
}

interface FontWeight {
  regular: string;
  medium: string;
  bold: string;
}

interface LetterSpacing {
  ['2XS']: string;
  XS: string;
  SM: string;
  MD: string;
  LG: string;
}

const color = {
  transparent: 'transparent',
  white: '#FFFFFF',
  grey50: '#F0F1F4',
  grey100: '#E7E9ED',
  grey200: '#C6C9D1',
  grey300: '#ADB0BC',
  grey400: '#6E7189',
  grey500: '#52526E',
  grey600: '#3D3C56',
  grey700: '#2D2B41',
  grey800: '#211F30',
  black: '#12101A',
  trueBlack: '#000000',
  neon500: '#E0FE2A',
  neon600: '#CAEC01',
  neon700: '#B1CE01',
  pink100: '#FEE3F7',
  pink500: '#FB72D7',
  pink800: '#8E046A',
  red100: '#FFDCDC',
  red400: '#FF5252',
  red500: '#CA0000',
  red600: '#A20000',
  red700: '#790000',
  red800: '#510000',
  scarlet100: '#F7DBD1',
  scarlet500: '#CE5123',
  scarlet800: '#53200E',
  orange100: '#FFEBD1',
  orange500: '#FF9815',
  orange700: '#D2511A',
  orange800: '#B44616',
  cactus100: '#F1F1D5',
  cactus500: '#ABAF38',
  cactus800: '#444616',
  green100: '#D1F2E2',
  green400: '#47CB8B',
  green500: '#2FA86D',
  green600: '#268657',
  green700: '#1C6541',
  green800: '#13432C',
  indigo100: '#E9EAFF',
  indigo300: '#C6C8FF',
  indigo500: '#A7ABFF',
  indigo700: '#4951FF',
  indigo800: '#00068D',
  // legacy colors
  yellow: '#FFE000',
  blue: '#4951FF',
  lavenderGrey: '#86819A',
  blueGrey: '#E8E9F3',
  pewter: '#B0BEC5',
  stone: '#EFF0F5',
  ash: '#6E6982',
  grey: '#5F666D',
  lilac: '#55516A',
  silver: '#E0E1EF',
  mint: '#CEEADC',
  indigo: '#3D3952',
};

const backgroundColor = {
  backgroundBody: color.white,
  backgroundPrimary: color.white,
  backgroundSecondary: color.grey50,
  backgroundTertiary: color.grey100,
  backgroundInversePrimary: color.black,
  backgroundInverseSecondary: color.grey800,
  backgroundScrim: 'rgba(0,0,0,0.5)',
  backgroundBlackTransparent: 'rgba(0,0,0,0.6)',
  actionBrand: color.neon500,
  actionBrandTransparent: 'rgba(224, 254, 42, 0.8)',
  backgroundSubduedNegative: color.red100,
  backgroundNegative: color.red500,
  backgroundWarning: color.orange100,
  backgroundSubduedPositive: color.green100,
  backgroundPositive: color.green500,
  backgroundSubduedInformational: color.indigo100,
  backgroundInformational: color.indigo700,
  backgroundDisabled: color.grey50,
  backgroundBlack: color.black,
  backgroundWhite: color.white,
  backgroundInverseDisabled: color.grey700,
  actionHover: 'rgba(0, 0, 0, 0.06)',
  actionInverseHover: 'rgba(255, 255, 255, 0.08)',
  actionPressed: 'rgba(0, 0, 0, 0.12)',
  actionInversePressed: 'rgba(255, 255, 255, 0.16)',
  backgroundTransparent: 'transparent',
};

const textColor = {
  textDefault: color.grey800,
  textSubdued: color.grey400,
  textDisabled: color.grey200,
  textInverse: color.white,
  textInverseSubdued: color.grey300,
  textInverseDisabled: color.grey400,
  textNegative: color.red500,
  textPositive: color.green500,
  textWarning: color.orange800,
  textBrand: color.neon500,
  textWhite: color.white,
  textBlack: color.black,
};

const iconColor = {
  iconDefault: color.grey800,
  iconDisabled: color.grey200,
  iconInverse: color.white,
  iconNegative: color.red500,
  iconPositive: color.green500,
};

const borderColor = {
  borderDefault: color.grey300,
  borderSubdued: color.grey100,
  borderPrimary: color.black,
  borderInverse: color.grey500,
  borderInverseSubdued: color.grey600,
  borderInversePrimary: color.white,
  borderWhite: color.white,
  borderNegative: color.red500,
  actionBrand: color.neon500,
};

const focusColor = {
  focus: 'rgba(18, 16, 26, 0.12)',
  focusInverse: 'rgba(255, 255, 255, 0.32)',
};

const elevation = {
  level1: '0px 0px 4px rgba(0, 0, 0, 0.7)',
  level2: '0px 0px 4px rgba(0, 0, 0, 0.06), 0px 2px 12px rgba(0, 0, 0, 0.08)',
  level3: '0px 4px 16px rgba(0, 0, 0, 0.24)',
  level4: '0px 4px 24px rgba(0, 0, 0, 0.32)',
  level5: '0px 4px 40px rgba(0, 0, 0, 0.12)',
};

const spacing = {
  '2XS': '4px',
  XS: '8px',
  SM: '12px',
  MD: '16px',
  LG: '24px',
  XL: '32px',
  '2XL': '40px',
  '3XL': '48px',
  '4XL': '56px',
  '5XL': '64px',
};

const margin = {
  mobile: {
    page: spacing.MD,
  },
  desktop: {
    page: spacing.LG,
    panel: spacing.LG,
  },
};

const borderRadius = {
  XS: '2px',
  SM: '4px',
  MD: '8px',
  LG: '12px',
  XL: '16px',
  '2XL': '24px',
};

const borderWidth = {
  default: '1px',
  focus: '2px',
};

const fontSize = {
  25: '12px',
  50: '14px',
  100: '16px',
  200: '18px',
  300: '20px',
  400: '24px',
  500: '28px',
  600: '32px',
  700: '36px',
  800: '40px',
  900: '48px',
  1000: '64px',
  1100: '80px',
};

const lineHeight = {
  25: '16px',
  50: '20px',
  100: '24px',
  200: '28px',
  300: '24px',
  400: '32px',
  500: '36px',
  600: '40px',
  700: '40px',
  800: '44px',
  900: '56px',
  1000: '72px',
  1100: '80px',
};

const fontWeight = {
  regular: '400',
  medium: '500',
  bold: '700',
};

const letterSpacing = {
  ['2XS']: '-1.5px',
  XS: '-1px',
  SM: '-0.25px',
  MD: '0px',
  LG: '0.5px',
};

const theme = assign(
  {color},
  {backgroundColor},
  {textColor},
  {iconColor},
  {borderColor},
  {focusColor},
  {elevation},
  {spacing},
  {margin},
  {borderRadius},
  {borderWidth},
  {fontSize},
  {lineHeight},
  {fontWeight},
  {letterSpacing},
  {
    fonts: {
      primary: 'DM Sans, sans-serif',
      secondary: 'gimlet-display-condensed, sans-serif',
      dm: 'DM Sans, sans-serif',
      gimlet: 'gimlet-display, serif',
      gimletCondensed: 'gimlet-display-condensed, sans-serif',
    },
    sizing: {
      mobile: {
        navHeight: '80px',
        altNavHeight: '65px',
      },
      desktop: {
        navHeight: '96px',
        sessionCreatorMenuWidth: '240px',
        creatorSideMenuWidth: '288px',
        creatorSideMenuCondensedWidth: '72px',
        maxGridWidth: '940px',
        leftPanel: '616px',
        rightPanel: '300px',
        sessionSidePanelS: '345px',
        sessionSidePanelM: '375px',
      },
    },
  }
);

declare module 'styled-components' {
  export interface DefaultTheme {
    color: Color;
    backgroundColor: BackgroundColor;
    textColor: TextColor;
    iconColor: IconColor;
    borderColor: BorderColor;
    focusColor: FocusColor;
    elevation: Elevation;
    spacing: Spacing;
    margin: Margin;
    borderRadius: BorderRadius;
    borderWidth: BorderWidth;
    fontSize: FontSize;
    lineHeight: LineHeight;
    fontWeight: FontWeight;
    letterSpacing: LetterSpacing;
    fonts: {
      primary: string;
      secondary: string;
      dm: string;
      gimlet: string;
      gimletCondensed: string;
    };
    sizing: {
      mobile: {
        navHeight: string;
        altNavHeight: string;
      };
      desktop: {
        navHeight: string;
        sessionCreatorMenuWidth: string;
        creatorSideMenuWidth: string;
        creatorSideMenuCondensedWidth: string;
        maxGridWidth: string;
        leftPanel: string;
        rightPanel: string;
        sessionSidePanelS: string;
        sessionSidePanelM: string;
      };
    };
  }
}

export {theme};
