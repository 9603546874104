import {sortBy} from 'lodash';
import {combineReducers, AnyAction} from 'redux';
import types from './types';
// Helpers
import {Category} from '@brightlive/shared/helpers/interfaces';

const navVisible = (state = true, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_NAV}`:
      return action.toggled;
    default:
      return state;
  }
};

const loginModalVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_LOGIN_MODAL}`:
      return action.toggled;
    default:
      return state;
  }
};

const loginModalPage = (state = 'login', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_MODAL_PAGE}`:
      return action.page;
    default:
      return state;
  }
};

const loginModalMessage = (state = '', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_MODAL_MESSAGE}`:
      return action.page;
    default:
      return state;
  }
};

const windowWidth = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case `${types.UPDATE_WINDOW_WIDTH}`:
      return action.windowWidth;
    default:
      return state;
  }
};

const windowHeight = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case `${types.UPDATE_WINDOW_HEIGHT}`:
      return action.windowHeight;
    default:
      return state;
  }
};

interface ToastInitState {
  toggled: boolean;
  text: string;
  toastType: 'default' | 'negative';
  icon: string;
}

const toastInitState: ToastInitState = {
  toggled: false,
  text: '',
  toastType: 'default',
  icon: '',
};

const toast = (state = toastInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_TOAST}`:
      return {
        toggled: action.toggled,
        text: action.text,
        icon: action.icon,
        toastType: action.toastType,
      };
    default:
      return state;
  }
};

interface ExitSessionCreatorModalInitState {
  toggled: boolean;
  newPath: string | null;
}

const exitSessionCreatorModalInitState: ExitSessionCreatorModalInitState = {
  toggled: false,
  newPath: null,
};

const exitSessionCreatorModal = (
  state = exitSessionCreatorModalInitState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.TOGGLE_EXIT_SESSION_CREATOR_MODAL}`:
      return {
        toggled: action.toggled,
        newPath: action.newPath,
      };
    default:
      return state;
  }
};

const categoryInitState: Category[] = [];

const categories = (state = categoryInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_ALL_CATEGORIES}_REQUEST`:
      return {...state, isFetching: true};
    case `${types.GET_ALL_CATEGORIES}_SUCCESS`:
      // Sort subcategories by name
      console.log('action.data', action.data);
      action.data.forEach(category => {
        category.subcategories = sortBy(category?.subcategories, [
          subcategory => subcategory.name,
        ]);
      });
      return {
        ...state,
        isFetching: false,
        data: sortBy(action.data, [category => category.name]),
      };
    case `${types.GET_ALL_CATEGORIES}_FAILURE`:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

// UI state
interface UIState {
  navVisible: boolean;
  loginModalVisible: boolean;
  loginModalPage: string;
  loginModalMessage: string;
  windowWidth: number;
  windowHeight: number;
  toast: ToastInitState;
  exitSessionCreatorModal: ExitSessionCreatorModalInitState;
  categories: {
    isFetching: boolean;
    data: Category[];
    error: string;
  };
}
// Used for redux selector hooks
export interface UIReducer {
  ui: UIState;
}

export default combineReducers({
  navVisible,
  loginModalVisible,
  loginModalMessage,
  loginModalPage,
  windowWidth,
  windowHeight,
  toast,
  exitSessionCreatorModal,
  categories,
});
