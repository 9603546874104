import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
// Types
import types from './types';

export interface InitialStripeConnectState {
  url: string;
  success: boolean;
  updated: boolean;
  error: unknown;
}
const initialStripeConnectState: InitialStripeConnectState = {
  url: '',
  success: false,
  updated: false,
  error: null,
};

const stripeConnect = (
  state = initialStripeConnectState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.RESET_STRIPE_ACCOUNT}`:
      return assign({}, initialStripeConnectState, {
        success: false,
        updated: false,
        error: null,
      });
    case `${types.CREATE_STRIPE_ACCOUNT}_REQUEST`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: null,
      });
    case `${types.CREATE_STRIPE_ACCOUNT}_SUCCESS`:
      return assign({}, state, {
        ...action?.data,
        success: true,
        updated: true,
        error: null,
      });
    case `${types.CREATE_STRIPE_ACCOUNT}_FAILURE`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

interface StripeState {
  stripeConnect: InitialStripeConnectState;
}
export interface PayoutReducer {
  payout: StripeState;
}

export default combineReducers({
  stripeConnect,
});
